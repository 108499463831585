@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes crashLanding {
  0% {
    transform: translateY(-200%) scale(2);
    opacity: 0;
  }
  60% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  75% {
    transform: translateY(-20px) scale(1.1);
  }
  90% {
    transform: translateY(10px) scale(0.95);
  }
  100% {
    transform: translateY(0) scale(1);
  }
}

.animate-crashLanding {
  animation: crashLanding 1s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards;
}
